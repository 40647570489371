import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'layout',
            component: () => import("./views/layout.vue"),
            children: [
                {
                    path: '/',
                    name: 'stats',
                    component: () => import("./views/home.vue"),
                },
                {
                    path: '/guide',
                    name: 'guide',
                    component: () => import("./views/guide.vue"),
                },
                {
                    path: '/fund/:fundAddress',
                    name: 'fund detail',
                    component: () => import("./views/fundDetail.vue"),
                    meta: { hideMenu: true }
                },
                {
                    path: '/admin',
                    name: 'admin login',
                    component: () => import("./views/login.vue"),
                },
                {
                    path: '/bug-reports-list',
                    name: 'bug reports',
                    component: () => import("./views/bugReportList.vue"),
                },
                {
                    path: '/bug-reports-list/:identifier',
                    name: 'bug report detail',
                    component: () => import("./views/bugReportDetail.vue"),
                },
                {
                    path: '/bug-categories',
                    name: 'bug categories',
                    component: () => import("./views/bugCategories.vue"),
                },
            ]
        },
    ];
};