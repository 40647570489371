import { ethers } from "ethers";

export class ConnectedAccount {
    address: string;
    provider: ethers.providers.JsonRpcSigner;

    constructor(){
        this.address = "";
        this.provider = null;
    }
}

export class TokenInFund {
    contractAddress: string;
    title: string;
    imageUrl: string;
    percentage: number; 

    constructor(){
        this.contractAddress = "";
        this.title = "";
        this.imageUrl = "";
        this.percentage = 0;
    }
}

export class Fund {
    contractAddress: string;
    imageUrl: string;
    title: string;
    marketCap: number;
    price: number;
    allTimeHigherPrice: number;
    lastYearPercentageString: string;
    lastYearPercentage: number;
    lastMonthPercentageString: string;
    lastMonthPercentage: number;
    lastWeekPercentageString: string;
    lastWeekPercentage: number;
    yesterdayPercentageString: string;
    yesterdayPercentage: number;
    lastHourPercentageString: string;
    lastHourPercentage: number;
    tokens: TokenInFund[];

    constructor(){
        this.contractAddress = "";
        this.imageUrl = "";
        this.title = "";
        this.marketCap = 0;
        this.price = 0;
        this.allTimeHigherPrice = null;
        this.lastYearPercentageString = "";
        this.lastYearPercentage = null;
        this.lastMonthPercentageString = "";
        this.lastMonthPercentage = null;
        this.lastWeekPercentageString = "";
        this.lastWeekPercentage = null;
        this.yesterdayPercentageString = "";
        this.yesterdayPercentage = null;
        this.lastHourPercentageString = "";
        this.lastHourPercentage = null;
        this.tokens = [];
    }
}

export class WithdrawPensingRequestVM {
    tokenAmount: number;
    withdrawApprovedDone: boolean;

    constructor(){
        this.tokenAmount = 0;
        this.withdrawApprovedDone = false;
    }
}