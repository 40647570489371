
import { mixins, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { BaseFormField } from './mixins/baseFormField';
import CheckboxField from './input/checkboxField.vue';
import inputDate from './input/inputDate.vue';
import InputFile from './input/inputFile.vue';
import inputMail from './input/inputMail.vue';
import inputNumber from './input/inputNumber.vue';
import inputPassword from './input/inputPassword.vue';
import inputSearch from './input/inputSearch.vue';
import inputText from './input/inputText.vue';
import inputTime from './input/inputTime.vue';
import inputCodeConfirmation from './input/inputCodeConfirmation.vue';
import inputRange from './input/inputRange.vue';

@Options({
    components: {
        CheckboxField,
    }
})
export default class FormField extends mixins(BaseFormField) {
    googlePlace = null;

    get inputComponent() {
        if (this.type == "text")
            return inputText;
        else if (this.type == "date")
            return inputDate;
        else if (this.type == "email")
            return inputMail;
        else if (this.type == "password")
            return inputPassword;
        else if (this.type == "search")
            return inputSearch;
        else if (this.type == "number")
            return inputNumber;
        else if (this.type == "file")
            return InputFile;
        else if (this.type == "time")
            return inputTime;
        else if (this.type == "CodeConfirmation")
            return inputCodeConfirmation;
        else if (this.type == "range")
            return inputRange;

        return null;
    }

    returnValue(array: any[], key: string, shortName: boolean = false) {
        var object = array.find(x => {
            return x.types.find(y => {
                return y == key
            })
        });

        if (object) {
            if (!shortName)
                return object.long_name;
            return object.short_name;
        }
        return "";
    }

    updatePrefix(selectedPrefix: string) {
        this.$emit("changedPrefix", selectedPrefix);
    }
}
