import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import site from '@/views/site';

const routes: Array<RouteRecordRaw> = [
    ...site.routes(),
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        window.scrollTo({ 
            left: savedPosition ? savedPosition.left : 0, 
            top: savedPosition ? savedPosition.top : 0,
            behavior: <any>'instant'
        });
    }
})

export default router