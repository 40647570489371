
import WithModel from '../mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class inputCodeConfirmation extends mixins(WithModel) {
    
    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: false
    }) readonly: boolean;
    @Prop({
        default: false
    }) required: boolean;

    @Prop() codeLength: number;

    @Prop() step: number;
    @Prop() min: number;
    @Prop() max: number;

    completeCode: string = "";

    mounted(){
        this.initCompleteCode();
    }

    @Watch("codeLength")
    initCompleteCode(){
        var result = this.localValue;
        
        if(result.length == this.codeLength)
            return result;
        
        var diffLength = this.codeLength - result.length;
        for(var i = 0; i < diffLength; i++)
            result += " ";
        
        this.completeCode = result;
    }

    checkDelete(event, index){
        var key = event.which || event.keyCode || event.charCode;

        if(key != 8 && key != 46)
            return;

        var indexToDelete = 0;
        //del button
        if(this.completeCode[index].trim()){
            indexToDelete = index;
        }
        else if(key == 8 && index > 0){
            indexToDelete = index - 1;
        } else if(key == 46 && index < this.codeLength){ //canc button
            indexToDelete = index + 1;
        }

        var temp = "";
        temp = this.replaceAt(this.completeCode, indexToDelete, " ");

        this.localValue = temp;
        this.completeCode = "";

        this.$nextTick( () => {
            this.completeCode = temp;
            (<any>this.$refs["input_" + (indexToDelete)]).focus();
        })

    }

    inserted(event, index){
        if(event.inputType == "insertFromPaste")
            return;
    
        var value = event.data;
        value = value.replace(/[^0-9]+/g, '')

        if(value.length > 1){
            this.pastedAction(value);
            return;
        }

        var temp = ""
        if(!value){
            temp = this.replaceAt(this.completeCode, index, " ");
        } else {
            temp = this.replaceAt(this.completeCode, index, value);
        }

        this.localValue = temp;
        this.completeCode = "";

        this.$nextTick( () => {
            this.completeCode = temp;
        })

        if(value && value.replace(/[^0-9]+/g, '') && index < this.codeLength - 1)
            (<any>this.$refs["input_" + (index + 1)]).focus();
    }

    replaceAt(text, index, replacement) {
        return text.substring(0, index) + replacement + text.substring(index + 1);
    }

    pasted(event){
        var clipboardData = event.clipboardData || event.originalEvent.clipboardData || (<any>window).clipboardData;
        var pastedData = clipboardData.getData('text');

        this.pastedAction(pastedData);
    }

    pastedAction(text: string){
        var temp = text.replace(/[^0-9]+/g, '');
        for(var i = 0; i < this.codeLength - temp.length; i++)
            temp += " ";

        if(temp.length > this.codeLength)
            temp = temp.substr(0, this.codeLength);

        this.localValue = temp;
        this.completeCode = "";

        setTimeout(() => {
            this.completeCode = temp;
        }, 100);
    }

}
