import moment from 'moment';
import { getUniversalDate } from './utils';
import store from './store';

export default {

    date(value: any, _format: string, _showTime: boolean) {
        if (value) {
            value = getUniversalDate(value);
            let format;
            if(!_format){
                format = 'YYYY MMMM DD';
                if(_showTime)
                    format += ' - HH:mm';
            } else {
                format = _format;
            }

            return moment(value).locale("en-US").format(format);
        }
    },

    time(value: any, _format: string, _showTime: boolean) {
        if (value) {
            let format;
            if(!_format){
                format = 'HH:mm';
            } else {
                format = _format;
            }

            return moment(value).locale("en-US").format(format);
        }
    },

    currency(value: any, decimals: number = 0) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    tokenPrice(value: any, decimals: number) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let ris = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            return ris;
        }
    },

    age(value: any) {
        if(!value)
            return "";
        
        var date = getUniversalDate(value);
        var today = new Date();

        var age = today.getFullYear() - date.getFullYear();
        var m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age--;
        }

        return age;
    },

    getChatTextFormatted(text: string){
        var formattedText = text.replaceAll("\n", "<br />");
        return formattedText;
    },

    cutNameTooLong(name: string){

        if(name.length <= 12)
            return name;

        return name.substring(0, 12) + "...";
    },

    formatTimeFromSeconds(time: number) {
        var timeArr: any = [
            Math.floor((((time % 31536000) % 86400) % 3600) / 60),
            ((((time % 31536000) % 86400) % 3600) % 60)
        ];

        timeArr[1] = Math.floor(timeArr[1]);

        for (var i = 0; i < timeArr.length; i++) {
            if (timeArr[i] < 10) {
                timeArr[i] = '0' + timeArr[i];
            };
        }
        return timeArr[0] + ':' + timeArr[1];
    }
}