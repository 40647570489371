
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import { StorageServices } from './services/StorageServices';
import { BlockchainServices } from './services/blockchainServices';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    created() {
    }
    

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }


    async mounted(){
        //nell eventualita che un utente si disconnetta o cambi account senza disconnettersi stiamo in ascolto su accountsChanged
        window.ethereum.on('accountsChanged', (accounts) => {
            var isConnected = BlockchainServices.connectedMetamask();    
            console.log(isConnected)
            if (accounts.length == 0) {//se si disconnette svuotiamo lo store 
                    StorageServices.setWalletAddress(undefined);
                    return
            } else if(accounts.length !== 0 && isConnected){ //se cambia address nel wallet ma è ancora connesso chiamiamo connectingMetaMask che consulta l'attuale 
                    BlockchainServices.connectingMetaMask()
                    window.location.reload()
                    return
            }
        });
    }
        
    
}
