import { wordpressUrl } from '@/config';
import router from "./router";
import { BlockchainServices } from './services/blockchainServices';
import { AirDropBugHuntingClient } from './services/Services';
import { StorageServices } from './services/StorageServices';
import store from "./store";

let viewWithServices = null;

export function assignViewWithServices(viewWithServicesComponent){
    viewWithServices = viewWithServicesComponent;
}

export function getViewWithServices(){
    return viewWithServices;
}

export function routerReplaceAndPush(replaceUrl: string, pushUrl: string){
    router.replace(replaceUrl)
    .then( () => {
        router.push(pushUrl);
    }).catch( () => {
        console.error("router replace error!");
    })
}

export function removeSpaces(text: string) : string {
    if(!text)
        return text;

    var temp = text.trim();

    while(temp.indexOf("  ") != -1)
        temp = temp.replace("  ", " ").trim();

    return temp;
}

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function removeBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "hidden";
}
export function addBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "auto";
}

export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function getFileFromUri(uri: string): Promise<File>{
    if(uri.indexOf('://') === -1) 
        uri = "file://" + uri;

    let prom = new Promise<File>((resolve, reject) => {
        (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
            fileEntry.file((resFile) => {
                resolve(resFile);
            });
        }, err => {
            console.log(err)
        })
    });

    return prom;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if(!file){
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        if(!window.cordova){
            var url = URL.createObjectURL(file);
            resolve(url);
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function _toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
}

export async function connectMetamask(): Promise<string>
{
    var ris = await BlockchainServices.connectingMetaMask();
    // var swithToMumbai = await BlockchainServices.switchMumbaiChain();
    store.state.walletAddress = ris.address;

    AirDropBugHuntingClient.login(store.state.walletAddress, false).then(x => {});

    return ris.address;
}

export function checkRoleIfIsAdmin(): boolean
{
    var loggedUser = StorageServices.getLoggedUser();
    return loggedUser.role == 'admin';
}