import { createStore } from 'vuex';
import * as OM from '@/Model';

let store = {
    state: {
        showSpinner: 0,
        selectedLanguage: "",
        loggedUser: new OM.LoggedUserVM(),
        deviceReady: false,
        walletAddress: "",
        alreadySigned: false
    },
    mutations: {
        updatedLanguage(state){
            console.log(state);
        }
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

export default createStore(store);