import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19b90963"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinnerContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!

  return (_ctx.showSpinner)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_mini_spinner)
      ]))
    : _createCommentVNode("", true)
}