import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80d1d014"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "code_container" }
const _hoisted_2 = ["value", "tabindex", "onKeydown", "onInput", "readonly", "required", "min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codeLength, (position, index) => {
      return (_openBlock(), _createElementBlock("input", {
        ref_for: true,
        ref: 'input_' + index,
        type: "number",
        class: "fw_700",
        maxlength: "1",
        value: _ctx.completeCode[index],
        tabindex: _ctx.tabIndex,
        placeholder: "-",
        onPaste: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pasted && _ctx.pasted(...args))),
        key: position + index,
        onKeydown: ($event: any) => (_ctx.checkDelete($event, index)),
        onInput: ($event: any) => (_ctx.inserted($event, index)),
        readonly: _ctx.readonly,
        required: _ctx.required,
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step
      }, null, 40, _hoisted_2))
    }), 128))
  ]))
}