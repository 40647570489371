import { BigNumber, ethers } from "ethers";
import * as VM from '@/viewModel';
import { isLinearGradient } from "html2canvas/dist/types/css/types/image";
import { _toFixed } from "@/utils";
import { StorageServices } from "./StorageServices";
import { chainIdEnv, chainNameEnv, networkVersionEnv, nativeCurrencyNameEnv, nativeCurrencyDecimalsEnv, nativeCurrencySymbolEnv, rpcUrlsEnv, blockExplorerUrlsEnv } from "@/config";

// 2. Limiti di Rate
// Molti provider impongono limiti sul numero di richieste che un utente può fare per evitare il sovraccarico dei sistemi. 
//Questi limiti possono essere basati su un numero di richieste al secondo, al minuto o all'ora. 
//Superare questi limiti potrebbe portare a ritardi nelle risposte o al blocco temporaneo delle richieste in arrivo dall'utente.
//Quindi la velocità dell'applicativo non dipende da noi ma dal provider dell'utente. Qualsiasi then o promise che possa accellerare il processo, potrebbe sovraccaricare le richieste e farlo esplodere

// 3. Ottimizzazione e Caching
// Alcune chiamate agli smart contract possono essere ottimizzate utilizzando tecniche di caching, specialmente per le chiamate che leggono dati che non cambiano frequentemente. Il caching può ridurre significativamente il carico sul provider e migliorare le prestazioni complessive dell'applicazione.

class _BlockchainServices {

    provider: ethers.providers.Web3Provider;
    signer: ethers.providers.JsonRpcSigner;
    user: string;

    merlinFundABI: string = require("./ABI/merlinFundABI.json");
    ERC20ABI: string = require("./ABI/ERC20ABI.json");
    merlinFaucetABI: string = require("./ABI/merlinFaucetABI.json");

    merlinFaucetAddress: string =  "0x6e05B2620901F05DC7034e6770976FcFa63521dE"

    signMessageText: string = "I accept the risks of the beta versIon ";

    //Verifica se c'è già un privider/wallet connesso
    async connectedMetamask(): Promise<boolean>
    {
        try {
            console.log("polygon v0 beta warning")
            // Controlla se Metamask è installato
            if (window.ethereum && window.ethereum.isMetaMask) {
              // Verifica se l'utente è connesso senza aprire il popup di autorizzazione
              const accounts = await window.ethereum.request({ method: 'eth_accounts' });
              
              if (accounts.length > 0) {
                // L'utente è connesso e ha almeno un account
                await this.switchCorrectChain()
                return true;
              } else {
                // L'utente non è connesso
                return false;
              }
            } else {
              // Metamask non è installato
              return false;
            }
          } catch (error) {
            console.error(error);
            return false;
          }
    }

    //Per collegare il prorpio wallet
    async connectingMetaMask()
    {
        // A Web3Provider wraps a standard Web3 provider, which is
        // what MetaMask injects as window.ethereum into each page
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        // console.log(provider);
        await this.switchCorrectChain()

        // MetaMask requires requesting permission to connect users accounts
        await this.provider.send("eth_requestAccounts", []);

        // The MetaMask plugin also allows signing transactions to
        // send ether and pay to change state within the blockchain.
        // For this, you need the account signer...
        this.signer = this.provider.getSigner();
        this.user = (await this.signer.getAddress()).toLowerCase();


        var ris = new VM.ConnectedAccount();
        ris.address = this.user;
        ris.provider = this.signer;

        return ris;
    }

    //switch mumbai network
    async switchCorrectChain() {
        var networkVersion = window.ethereum.networkVersion;
    
        if (networkVersion == networkVersionEnv) {
            return false;
        }
    
        if (!window.ethereum) {
            alert("Metamask not found!");
            return false;
        }
    
        const chainId = chainIdEnv; // Chain ID per Polygon Mumbai come stringa esadecimale da env
    
        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }],
                });
            } catch (err) {
                if (err.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: chainNameEnv,
                                    chainId: chainId,
                                    nativeCurrency: { name: nativeCurrencyNameEnv, decimals: nativeCurrencyDecimalsEnv, symbol: nativeCurrencySymbolEnv },
                                    rpcUrls: rpcUrlsEnv,
                                    blockExplorerUrls: blockExplorerUrlsEnv,
                                },
                            ],
                        });
                    } catch (err) {
                        console.error(err);
                        return false;
                    }
                } else {
                    console.error(err);
                    return false;
                }
    
                networkVersion = window.ethereum.networkVersion;
                this.connectingMetaMask();
            }
        }
    }
    
    // Funzione per firmare un messaggio
    async signMessage() {
        // Calcolo del hash del messaggio
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        await this.provider.send("eth_requestAccounts", []);

        this.signer = this.provider.getSigner();

        // Firma del messaggio
        const signature = await this.signer.signMessage(this.signMessageText);
        
        return signature;
    }

    //function that returns the contract to interact with. 
    //It needs ABI link from Block Explorer, because inside it performs the deserialization of json, 
    //contract address and supplier (provided by the Metamask link function)
    async setContract(contractAddress: string, contractABI: any, signer: ethers.providers.JsonRpcSigner)
    {
        try
        {
            let jsonInterface = JSON.parse(contractABI.result);
        
            const contract = new ethers.Contract(contractAddress, jsonInterface, signer);

            return contract;
        }
        catch(ex)
        {
            console.log("Error in setContract. " + ex)
        }
        
    }

    async setSigner()
    {
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        await this.provider.send("eth_requestAccounts", []);

        this.signer = this.provider.getSigner();
    }

    async faucet(): Promise<string>
    {
        try
        {
            await this.setSigner();

            var faucetContract = await this.setContract(this.merlinFaucetAddress, this.merlinFaucetABI, this.signer);

            var tx = await faucetContract.faucet();

            await tx.wait();

            return "Ok";
        }
        catch(ex)
        {
            console.log("Error in faucet: " + ex);
            return ex.reason;
        }

    }

    //Per tirar giù direttamente onchain l'elenco dei token contenuti nel fonto. Ritorna appunto un array di string
    async getTokensInFund(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<string[]>
    {
        try
        {
            var merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var ris: string[] = [];
            var counter = 1;

            //Partiamo dalla posizione 1, perchè nello smart contract solitamente c'è l'address(0) alla posizione 0 (per motivi di logica)
            var contract = await merlinFund.tokenInFund(counter);

            while(contract != "0x0000000000000000000000000000000000000000")
            {
                ris.push(contract);
                counter = counter + 1;
                contract = await merlinFund.tokenInFund(counter);
            }

            return ris;
            // console.log(ris);

        }
        catch(ex)
        {
            console.log("Error in getTokensInFund. " + ex);
        }
    }

    //Questa funzione restituisce la percentuale che ogni token occupa nel fondo. Valore delle percentuale giù in percentuale (es percentages : 20 => 20 = 20%)
    async getTokensInFundWithPercentages(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<tokenInFund[]>
    {
        try
        {
            var merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var tokenInFundCount = Number(await merlinFund.tokenInFundCount());

            var ris: tokenInFund[] = [];

            for(let counter = 1; counter<=tokenInFundCount; counter++)
            {
                var contract = await merlinFund.tokenInFund(counter);
                var percentage = Number(await merlinFund.percentages(contract));

                var tokenContract = await this.setContract(contract, this.ERC20ABI, signer);
                var name = await tokenContract.name();

                if(name.includes("MERLIN") || name.includes("Merlin") || name.includes("merlin"))
                {
                    name = name.replace(new RegExp("MERLIN", 'g'), '');
                    name = name.replace(new RegExp("Merlin", 'g'), '');
                    name = name.replace(new RegExp("merlin", 'g'), '');
                }

                var item = new tokenInFund(contract, name, percentage / (1e2));

                ris.push(item);
            }
            return ris;
        }
        catch(ex)
        {
            console.log("Error in getTokensInFundWithPercentages. " + ex);
        }
    }

    //Ritorna l'url dell'immagine simbolo del token
    async getImageSymbolUrl(contract: string, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            var token = await this.setContract(contract, this.ERC20ABI, signer);
            var symbol: string = await token.symbol();
            // console.log("Symbol: ", symbol);

            // symbol = symbol.substring(0, symbol.length - 2);
            // console.log("Symbol: ", symbol);

            if(symbol == "SOL")
            {
                symbol = "wormholesolana";
            }

            if(symbol == "ETH")
            {
                symbol = "WETH";
            }
            
            if(symbol == "BTC")
            {
                symbol = "WBTC";
            }

            if(symbol == "LINK")
            {
                symbol = "chainlinktoken";
            }

            if(symbol == "UNI")
                symbol = "uniswap";

            if(symbol == "JPYC")
                symbol = "jpycoin";

            var url = "https://polygonscan.com/token/images/" + symbol + "_32.png"

            // console.log(url);
            return url;
        }
        catch(ex)
        {
            console.log("Error in getImageSymbolUrl. " + ex);
        }
    }
    
    //Funzione che restituisce il prezzo in dollari del fondo
    async getFundPriceInDollar(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<number>
    {
        try
        {
            var merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var ris = Number(await merlinFund.getAveragePrice());
            ris = ris / (1e6);

            return ris;
        }
        catch(ex)
        {
            console.log("Error in getFundPriceInDollar. " + ex);
        }
    }

    //Funzione che serve a controllare se bisogna fare l'approve, oppure è già stato eseguito

    async checkSufficientAllowance(fundContract: string, amountInDollar : number, signer: ethers.providers.JsonRpcSigner): Promise<boolean>
    {
        try
        {
            if(this.user == undefined || this.user == null){ //controllare sempre che metamask sia iniettato spesso con un semplice cambio pagina o f5 questo si scollega è errato (non si riapre metamsk esegue solo il controllo!)
                this.connectingMetaMask()
            }
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);
            var usdContractAddress = await merlinFund.usdAddress();
            const usdContract = await this.setContract(usdContractAddress, this.ERC20ABI, signer);
            var allowance = Number(await usdContract.allowance(this.user, fundContract));
            amountInDollar = amountInDollar * (1e6);

            if(allowance >= amountInDollar)
            {
                return true;
            }

            return false;
        }
        catch(ex)
        {
            console.log("Error in checkSufficientAllowance. " + ex);
        }
    }

    //chiamata per gestire lo spending in modo dinamico se un utente approva 20 e poi cambia e vuole spendere  50 non deve partire il buy ma ben si deve riapprovare , attualmente se cambio in 50 il buy parte mi da una tx vuota 
    async checkCurrentAllowance(fundContract: string, amountInDollar : number, signer: ethers.providers.JsonRpcSigner): Promise<number>
    {
        try
        {
            if(this.user == undefined || this.user == null){ 
                this.connectingMetaMask()

            }
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);
            var usdContractAddress = await merlinFund.usdAddress();
            const usdContract = await this.setContract(usdContractAddress, this.ERC20ABI, signer);
            var allowance = Number(await usdContract.allowance(this.user, fundContract));


            return allowance;
        }
        catch(ex)
        {
            console.log("Current allowance not fund " + ex);
        }
    }

    //Quando si invoca questa funzione, si aprirà metamask all'utente, il quale dovrà fare tutto da metamask
    async approve(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<boolean>
    {
        try
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var usdContractAddress = await merlinFund.usdAddress();

            const usdContract = await this.setContract(usdContractAddress, this.ERC20ABI, signer);

            var tx = await usdContract.approve(fundContract, ethers.utils.parseEther("1"));

            await tx.wait();

            return true;
        }
        catch(ex)
        {
            console.log("Error in approve. " + ex);
            throw new Error(ex.toString());
        }
    }

    async buy(fundContract: string, amountInDollar: number, signer: ethers.providers.JsonRpcSigner): Promise<{ success: boolean, txHash: string | null }> //aggiunta promise diversa per gestire se un utente rifiuta
    {

        try
        {
            if(this.signer == undefined || this.signer == null){ //controllare sempre che metamask sia iniettato spesso con un semplice cambio pagina o f5 questo si scollega è errato (non si riapre metamsk esegue solo il controllo!)
                this.connectingMetaMask()
            }
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var tx = await merlinFund.deposit(BigNumber.from(amountInDollar*1e6))
            
            return { success: true, txHash: tx.hash }

        }
        catch(ex)
        {
            console.log("Error in buy. " + ex.reason); 
            return { success: false, txHash: ("Error in buy. " + ex.reason) }
        }
    }


    //L'utente deve prima fare una richiesta di prelievo. Successivamente potrà fare il claim
    async withdrawRequest(fundContract: string, amount: number, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            var amountString = amount.toFixed(18)
            var amountWei = ethers.utils.parseUnits(amountString, "ether");
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var tx = await merlinFund.withdrawRequest(amountWei);
            
            await tx.wait();

            return tx.hash;

        }
        catch(ex)
        {
            throw new Error(ex);
            console.log("Error in withdrawRequest. " + ex);
        }
    }

    async getFundBalance(fundContract: string, priceInDollar: number, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            var wallet = await signer.getAddress();

            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var balance = Number(await merlinFund.balanceOf(wallet));

            balance = balance/(1e18);

            var ris = _toFixed(balance);

            return ris;
        }
        catch(ex)
        {
            console.log("Error in getFundBalance. " + ex);
        }
    }      

    async getUSDBalance(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<number>
    {
        try
        {
            var wallet = await signer.getAddress();
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);
            const usdContractAddress = await merlinFund.usdAddress();

            const token = await this.setContract(usdContractAddress, this.ERC20ABI, signer);
            // const decimals = Number(await token.decimals());

            var balance = Number(await token.balanceOf(wallet));

            // var ris = balance / (10**decimals);
            var ris = balance / (1e6);

            return ris;
        }
        catch(ex)
        {
            console.log("Error in getUSDBalance. " + ex);
        }
    }

    //Questa funzione serve per controllare se l'utente ha già una richiesta pendente. 
    //Se inoltra un'altra richiesta non si somma, ma si sostituisce nello smart contract
    async checkPendingWithdrawRequest(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<number>
    {
        try
        {

            var user = await signer.getAddress();

            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var result = Number(await merlinFund.pendingWithdrawRequests(user));

            // console.log("resulòt", result);

            // if(result != 0)
            // {
            //     return result / (1e6);
            // }

            // return 0;

            return result;

        }
        catch(ex)
        {
            console.log("Error in checkPendingWithdrawRequest. " + ex);
        }
    }


    //Per ricevere gli usd nel proprio wallet dopo la richiesta di prelievo
    async claim(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var tx = await merlinFund.claim();

            return tx.hash;

        }
        catch(ex)
        {
            
            // console.log(ex.message);
            if(ex.message.includes("insufficient liquidity in the fund")){
                throw new Error("Insufficient liquidity in the fund. Waiting for the next round of swaps");
            }

            throw new Error(ex.message);
        }
    }

    //Per avere il nome del fondo
    async getName(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var name = await merlinFund.name();

            return name;

        }
        catch(ex)
        {
            console.log("Error in getName. " + ex);
        }
    }

    async getSymbol(fundContract: string, signer: ethers.providers.JsonRpcSigner): Promise<string>
    {
        try
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var symbol = await merlinFund.symbol();

            return symbol;

        }
        catch(ex)
        {
            console.log("Error in getSymbol. " + ex);
        }
    }

    async getMarketCap(fundContract: string, tokenPrice: number, signer: ethers.providers.JsonRpcSigner): Promise<number>
    {
        try
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);

            var totalSupply = Number(await merlinFund.totalSupply());

            var marketCap = (totalSupply * tokenPrice)/(1e6);
            return marketCap;

        }
        catch(ex)
        {
            console.log("Error in getMarketCap. " + ex);
        }
    }

    //aggiunta token all'interface di Metamask func frenk cacacazzi
    async importCustomToken(fundContract: string, signer: ethers.providers.JsonRpcSigner)
    {
        const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);
        const tokenSymbol = await merlinFund.symbol();
        const tokenDecimals = await merlinFund.decimals();
        const tokenImage = 'https://www.tally.xyz/_next/image?url=https%3A%2F%2Fstatic.tally.xyz%2Fd977448d-003c-4430-8f3b-2aab2c7f66c2_original.png&w=256&q=75';
        
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: fundContract, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
                },
            });
            
            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
        console.log(error);
        }
    }

    async getTokenSymbol(fundContract: string, signer: ethers.providers.JsonRpcSigner)
    {
        try 
        {
            const merlinFund = await this.setContract(fundContract, this.merlinFundABI, signer);
            const tokenSymbol = await merlinFund.symbol();
            return tokenSymbol;
        }
        catch(error) {
            console.log("error in getTokenSymbol: ", error);
        }
    }
}

export class tokenInFund {
    contrat: string;
    name: string;
    percentage: number;

    constructor(contract: string, name: string, percentage: number){
        this.contrat = contract;
        this.name = name;
        this.percentage = percentage;
    }
}

export let BlockchainServices = new _BlockchainServices();