export let baseUrl = process.env.VUE_APP_ENDPOINT;
export let startUrl = process.env.VUE_APP_BASEURL;
export let siteUrl = process.env.VUE_APP_WEBSITE;
export let wordpressUrl = process.env.VUE_APP_WORDPRESSURL;

//custom variable set in package.json
export let debugAnalytics = process.env.VUE_APP_DEBUG.trim() == "true"; 

export const platformList = {
    web: "web",
}

//setting della chain a cui collegarsi 
export let chainIdEnv = process.env.VUE_APP_CHAINID;
export let chainNameEnv = process.env.VUE_APP_CHAIN_NAME;
export let networkVersionEnv = process.env.VUE_APP_NETWORKVERSION;

export let nativeCurrencyNameEnv = process.env.VUE_APP_CURRENCY_NAME;
export let nativeCurrencyDecimalsEnv = parseInt(process.env.VUE_APP_CURRENCY_DECIMALS);
export let nativeCurrencySymbolEnv = process.env.VUE_APP_CURRENCY_SYMBOL;

export let rpcUrlsEnv: string[] = JSON.parse(process.env.VUE_APP_CHAIN_RPC_URLS)
export let blockExplorerUrlsEnv: string[] = JSON.parse(process.env.VUE_APP_CHAIN_EXPLORER_URLS)